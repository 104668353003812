@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500&family=Overpass:ital@0;1&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: Poppins;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
:root {
  /* fonts */
  /* --font-poppins: Poppins; */
  --font-work-sans: "Work Sans";

  /* font sizes */
  --font-size-4: 0.25rem;
  --font-size-6: 0.375rem;
  --font-size-8: 0.5rem;
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-26: 1.625rem;
  --font-size-28: 1.75rem;
  --font-size-30: 1.875rem;
  --font-size-32: 2rem;
  --font-size-34: 2.125rem;
  --font-size-36: 2.25rem;
  --font-size-38: 2.375rem;
  --font-size-40: 2.5rem;

  /*  font weight  */
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;

  /* Colors */
  --color-dimgray: #525252;
  --color-black: #000;
  --color-aliceblue: #eff6ff;
  --color-lightblue: #5fa4fb;
  --color-lightgrey: #f0f4f4;
  --color-normalgrey: #888888;
  --color-lightGrey: #f6f6f6;
  --color-dimgrey: #d3d3d3;
  --color-normalwhite: #ffffff;
  --color-normalblack: #000000;
  --color-darkblack: #2d2d2d;

  /* Gaps */
  ---btn-regu-gap: 6px 14px 6px 14px;
  /* --gap-8xs: 5px; */
  /* --gap-3xs: 10px; */

  /* Border radiuses */
  --btn-radius: 30px;
}
select option {
  background-color: transparent;
}

/**  Login.jsx Start from here  **/

.login-container {
  display: flex;

  justify-content: space-between;
  height: 100vh;
  background-color: #eff6ff;
}

.retailer-logo {
  text-align: center;
}
.retailer-logo span {
  font-size: 48px;
  line-height: 3.813rem;
  font-weight: var(--font-weight-4);
  font-family: "Overpass";
  color: var(--color-lightblue);
}
.retailer-logo p {
  font-size: 4rem;
  line-height: 3.813rem;
  font-weight: var(--font-weight-4);
  font-family: "Overpass";
  color: var(--color-lightblue);
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.form-container input[type="text"] {
  width: 100%;
  height: 100%;
  border-radius: 21px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  display: block;
  padding-left: 60px;
}

.form-container input[type="text"]::placeholder {
  font-size: 1.125rem;
  font-weight: var(--font-weight-3);
  line-height: 1.25rem;
  align-items: center;
  font-family: Inter;
}

.form-container input[type="password"]::placeholder {
  font-size: 1.125rem;
  font-weight: var(--font-weight-3);
  line-height: 1.25rem;
  align-items: center;
  font-family: "Inter";
}

.checkbox {
  font-family: Inter;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-16);
  line-height: 16px;
  color: var(--color-lightblue);
  display: flex;
  gap: 7px;
  cursor: pointer;
}
.form-container input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
input:focus {
  outline: none;
}
.user-form {
  position: relative;
  width: 500px;
  height: 65px;
}

.login-form-imag {
  width: 30px;
  height: 30px;
  position: absolute;

  top: 16px;
  left: 10px;
  bottom: 5px;

  background-color: white;
}

.eye-btn {
  position: absolute;

  cursor: pointer;
  right: 15px;
  top: 30px;
  border: none;
  background-color: white;
}

.pas-form {
  position: relative;
  width: 500px;
  height: 65px;
  margin-top: 17.47px;
  margin-bottom: 34.43px;
}
.psd-toggle {
  width: 100%;
  height: 100%;
  border-radius: 21px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  display: block;
  padding-left: 60px;
}

.login-btn {
  width: 500px;
  height: 65px;
  border-radius: 21px;
  margin-top: 10px;
  cursor: pointer;
  border: none;

  font-size: 1.375rem;
  font-weight: var(--font-weight-7);
  line-height: 1.25rem;
  text-align: center;
  background-color: var(--color-lightblue);
  color: var(--color-normalwhite);
  font-family: Inter;
}
.login-btn:hover {
  box-shadow: 0px 4px 10px 0px var(--color-normalblack) 4d;
  transition: box-shadow 0.5s;
}
.form-container a {
  margin-top: 6px;
  text-align: end;
  font-size: var(--font-size-16);
  line-height: 1.361rem;
  font-weight: var(--font-weight-5);
  font-family: Inter;
  color: var(--color-lightblue);
  text-decoration: none;
}

.Login_Fgpwd_container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Login.jsx End here  **/

/* Signup start Here */

.Signup-Adress-container {
  display: flex;
  margin: 10px 0px;
}

.Signup-autoAdress-container {
  flex: 8;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Signup-autoAdress-input {
  border: none;
  /* flex-grow: 2; */
  min-width: 418px;
  padding: 6px 15px;
  /* margin-left: 8px; */
}

.Signup-menualAdress-container {
  /* margin-top: 20px; */
  /* border: 1px solid; */
}

.Signup-switchingAdress-btn {
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  border-radius: var(--btn-radius);
  cursor: pointer;
}

/* Signup end Here */
