* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  margin: 0;
  padding: 0;
}

.div {
  position: relative;
  letter-spacing: -0.06em;
  line-height: 10px;
  font-weight: var(--font-weight-6);
}

@media (min-width: 1701px) {
  .button {
    width: 100% !important;
  }

  .main-part-ratailer {
    width: 95%;
    /* margin-left: 13%; */
  }

  .TeamDetailsRowData {
    gap: 4% !important;
    /* padding-left: 4% !important; */
  }

  .left-panel {
    width: 12vw !important;
  }

  .live-Price-main-section {
    /* margin-left: 13% !important; */
    width: 83vw;
  }

  .sideBarMenu {
    height: 88vh !important;
  }

  .team-data-actions {
    width: 16% !important;
  }
}

@media (min-width: 750px) and (max-width: 1280px) {
  .address-type {
    width: 30% !important;
    font-size: 11px !important;
  }

  .main-part-ratailer {
    /* margin-left: 17%; */
    width: 83vw !important;
  }

  .map-section {
    margin: 0px 0 0 10px !important;
    width: 321px !important;
    height: 196px !important;
    overflow-x: hidden !important;
  }

  .TeamDetailsRowData {
    flex-wrap: nowrap !important;
    gap: 2px !important;
  }

  .team-data-main {
    width: 181px !important;
  }

  .team-details {
    flex-direction: column !important;
  }

  .team-data-role {
    width: 166px !important;
  }

  .invite-model-content {
    height: 70% !important;
  }

  .TeamsTableHeading {
    width: 100% !important;
  }

  .dashboard-logo {
    /* height: 1vh !important; */
  }

  .Logo-image-sideMenu {
    width: 100%;
  }

  .left-part {
    /* width: 80% !important; */
    height: 46px !important;
    gap: 15px;
  }

  /* Hover state when it's not active */
  .left-part:not(.active):hover {
    background: rgb(21 67 176);
    /* slightly transparent blue */
    color: white;
  }

  .left-text {
    font-size: 10px !important;
    font-weight: 400;
    width: 100% !important;
  }

  .left-img {
    margin: 10px !important;
  }
}

.address-auto-complete {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.address-auto-complete div {
  padding: 10px;
  cursor: pointer;
}

.address-auto-complete div:hover {
  color: var(--color-lightblue);
}

.address-auto-complete .loading {
  padding: 10px;
}

.address-auto-complete .no-suggestions {
  padding: 10px;
}

#address:focus {
  border-color: var(--color-lightblue);
}

.login-container {
  display: flex;
  width: 100%;
  background-image: url("../public/Assets/Login/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  top: 0;
}

.bg-overlay {
  background: rgba(0, 67, 229, 0.3);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.form-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.logo {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 12px;
  margin: 0 auto;
  text-align: center;
}

section.login-box {
  border-radius: 0 !important;
  background-color: transparent;
}

section.login-box.main-box {
  background: rgba(255, 255, 255, 0.9);
  width: 457px;
  margin: 0 auto;
  border-bottom: 10px solid rgb(21 67 176);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 469px;
  padding-top: 0;
  gap: 6px;
}

.retailer-logo p {
  font-size: 18px;
  line-height: 58px;
  font-weight: 700;
  font-family: "Overpass";
  color: rgb(21 67 176);
}

.form-container input[type="text"] {
  width: 374px;
  height: 52px;
  border-radius: 5px;
  border: transparent;
  display: block;
  padding-left: 50px;
  background: rgba(224, 237, 255, 1);
  margin: 0;
  color: rgba(44, 58, 100, 1);
}

.psd-toggle {
  width: 374px;
  height: 52px;
  border-radius: 21px;
  border: 0.5px solid;
  border-radius: 50px;
  border: 1px solid rgba(44, 58, 100, 0.2);
  display: block;
  padding-left: 50px;
}

.login-btn {
  width: 145px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  line-height: 15.31px;
  margin-top: 30px;
}

.login-form-imag {
  background-image: url("../public/Assets/Login/email.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 21px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 17px;
  bottom: 5px;
  background-color: rgba(230, 229, 229, 0.2);
}

.eye-btn {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 15px;
  border: none;
  background-color: rgba(230, 229, 229, 0.2);
}

.pas-form {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 26px;
  margin-bottom: 10px;
}

.user-form {
  position: relative;
  width: auto;
  height: auto;
}

.checkbox {
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  color: rgba(44, 58, 100, 1);
  display: flex;
  gap: 7px;
  cursor: pointer;
}

.form-container a {
  margin-top: 6px;
  text-align: end;
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  color: rgba(44, 58, 100, 1);
  text-decoration: none;
}

.Login_Fgpwd_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

img.eye-login {
  width: 23px;
  height: 23px;
  display: block;
}

.login {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  margin-left: 10px;
}

input.psd-toggle {
  line-height: 14.36px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: transparent;
}

input.address {
  border: none;
  background: transparent;
}

.Signup-switchingAdress-btn {
  color: rgb(21 67 176);
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  background: transparent;
  text-decoration: underline;
  margin-top: 10px;
}

.Signup-Adress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin:0 20px 20px 0; */
}

.Signup-autoAdress-input {
  border: none;
  background: transparent;
  margin: 0;
  padding: 5px 0;
}

.checkbox {
  border-color: 1px solid rgb(21 67 176);
  accent-color: rgb(21 67 176);
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

/*create job page css*/

.left-panel {
  background: linear-gradient(180deg, #e1eaff 0%, rgba(255, 255, 255, 0) 100%);
  height: fit-content;
  /* position: fixed; */
  /* width: 17vw; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-part {
  display: flex;
  background: transparent;
  color: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 200px;
  height: 56px;
  margin: 0 15px;
  margin-bottom: 7px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  align-items: center;
  justify-content: left;
}

/* Hover state when it's not active */
.left-part:not(.active):hover {
  background: rgb(21 67 176);
  /* slightly transparent blue */
  color: white;
}

/* Active state */
.left-part.active {
  background: rgb(21 67 176);
  /* solid blue */
  color: white;
}

.left-img {
  margin: 10px 15px 10px 10px;
  width: 20%;
}

.left-text {
  margin: 18px 0px;
  font-size: 13px;
  font-weight: 400;
  /* width: 90%; */
  text-align: left;
}

.hide {
  display: none;
}

.left-img .default-image:hover {
  display: none;
}

.left-img .selected-image {
  display: none;
}

.left-part:hover .selected-image,
.left-part.active .selected-image {
  display: block;
}

.left-part:hover .default-image,
.left-part.active .default-image {
  display: none;
}

.img-border {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
}

.left-img {
  color: rgb(21 67 176);
}

.left-part .default-image {
  display: block;
  transition: all 0.5s;
}

.left-part .selected-image {
  display: none;
}

.left-part:hover .default-image {
  display: none;
}

.left-part:hover .selected-image {
  display: flex;
}

.left-part:active .default-image {
  display: none;
}

.left-part:active .selected-image {
  display: flex;
}

/*right part */

li.active {
  color: rgb(21 67 176);
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

/*create job design css*/

.address-input {
  width: 455px;
  height: 52px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: rgba(224, 237, 255, 1);
  outline: none;
  margin-left: 10px;
}

input.address-input {
  border: none;
  background: transparent;
}

.address-box {
  margin: 0 20px 20px 0;
}

.address-main-boxes {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.address-type {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
}

.unit-main-boxes {
  margin-left: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.unit-main-boxes > input {
  border: none;
  background: transparent;
}

.unit-no {
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
  width: 100%;
}

.street-type {
  width: 180px;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
}

.state-main-boxes {
  margin-right: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.state-code {
  width: 180px;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
}

.main-address-block {
  display: inline-block;
  margin: 0 10px;
}

/*different postal address css*/

@media only screen and (max-width: 767px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }

  .form-container input[type="text"] {
    width: 332px;
    display: block;
  }

  .psd-toggle {
    width: 334px;
  }

  .login-container {
    height: 590px;
  }

  .logo {
    top: 30px;
  }
}

@media only screen and (max-width: 460px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 0;
  }

  .form-container input[type="text"] {
    width: 400px;
    display: block;
  }

  .pas-form {
    width: 400px;
    display: inline-block;
  }

  .psd-toggle {
    width: 400px;
  }

  .login-container {
    height: 590px;
  }
}

.team-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  border-radius: 10px;
  margin: 15px 10px 0 20px;
  padding: 20px 20px;
}

.team-btn {
  position: relative;
  display: flex;
  gap: 20px;
  padding: 10px;
}

.btn-add {
  background: rgb(21 67 176);
  width: 87px;
  height: 41px;
  border-radius: 50px;
  top: 25px;
  right: 140px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.team-main-bg {
  background: rgba(224, 237, 255, 1);
  width: 104%;
  height: 55px;
  /* margin: 0 20px; */
}

.team-data-main {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
  width: 20%;
  text-align: left;
}

.team-data-role {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-email {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-mobile {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-actions {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 0px;
}

.team-details {
  display: flex;
  margin: 15px 15px;
  /* width: 191px; */
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
}

.add-action,
.view-action,
.notview-action {
  border: 0;
  padding: 0px 0;
  background: transparent;
  margin-right: 8px;
  cursor: pointer;
}

/* responsive design css */
@media only screen and (max-width: 767px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }

  .form-container input[type="text"] {
    width: 332px;
    display: block;
  }

  .psd-toggle {
    width: 334px;
  }

  .login-container {
    height: 590px;
  }

  .logo {
    top: 30px;
  }
}

@media only screen and (max-width: 460px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 0;
  }

  .form-container input[type="text"] {
    width: 400px;
    display: block;
  }

  .psd-toggle {
    width: 400px;
  }

  .login-container {
    height: 590px;
  }

  .main-part-ratailer {
    max-width: -webkit-fill-available;
  }
}

/* Invite Model Starts */

.invite-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: auto;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.invite-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.invite-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popupbottom-border {
  color: rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: 100%;
  /* margin: 0 -20px; */
  padding: 0;
}

.invite-model-close-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  background: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 23px;
  height: 23px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
}

.popup {
  background: rgb(207, 207, 207);
  width: 470px;
  height: 330px;
  margin: 0 auto;
}

.main-popup {
  margin: 20px 20px;
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  position: relative;
}

.popupbottomadd-border {
  border-bottom: 1px solid rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: -webkit-fill-available;
}

.popup-email {
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  line-height: 13px;
  width: 328px;
  height: 52px;
  border: 0;
  outline: 0;
}

.popup-checkbox {
  margin-top: 10px;
  margin-left: 10px;
}

input.popup-checkbox {
  border-color: #506eec;
  border: 1px solid rgb(21 67 176);
  width: 16px;
  height: 16px;
  vertical-align: sub;
  cursor: pointer;
}

span.check-text {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 400;
  display: inline-flex;
  margin-left: 10px;
  vertical-align: middle;
}

input.input-field {
  padding: 0 20px;
}

.btn-cancel {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 41px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  outline: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.button-invite {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 41px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  margin: 15px;
  outline: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.footer-popupbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.button-invite:hover {
  background: rgb(93, 112, 157);
  color: rgba(255, 255, 255, 1);
}

.btn-cancel:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

/* Invite Model Ends */

/* Add Model Starts */

.add-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-model-content {
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 0;
  width: auto;
  max-width: 1170px;
  max-height: 80vh;
  overflow-y: auto;
}

.add-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-model-close-btn {
  background: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 23px;
  height: 23px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.add-model-form {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 10px;
  margin-top: 18px;
}

.add-name {
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-email {
  /* margin-left: 20px; */
  padding: 0 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-phone {
  /* margin-left: 20px; */
  padding: 0 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-personphoto {
  margin-left: 20px;
  padding: 23px 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  height: 52px;
}

input.add-personphoto {
  width: 274px;
}

.photo-upload {
  position: relative;
  top: 0;
  right: 0;
}

.photo-main {
  position: absolute;
  right: 15px;
  top: 10px;
}

.add-cce {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 200px;
  height: 52px;
}

.what-servesection {
  display: flex;
  flex-direction: row;
  gap: 30px;
  /* margin-top: 20px; */
  font-size: 14px;
  font-weight: 400;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 70% !important;
  height: 64px;
  overflow: auto;
  margin-bottom: 20px;
}

.adddetail-input {
  padding: 12px 15px;
  border-radius: 5px;
  border: none;
  width: 200px;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  margin-right: 20px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
}

.add-ccephoto {
  margin-top: 20px;
  font-size: 10px;
  font-weight: 400;
  padding: 23px 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  height: 52px;
}

.ccephoto-size {
  /* padding-top: 20px; */
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  opacity: 50%;
  font-weight: 400;
}

input.add-ccephoto {
  width: 200px;
}

.addphoto-main {
  position: absolute;
  right: 30px;
  top: 30px;
}

.add-model-form input,
.add-model-form select,
.add-model-form textarea {
  border: 1px solid #ccc;
  border: 0;
}

.add-model-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.add-model-actions button {
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid rgb(21 67 176);
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.5s ease-in-out;
}

.add-model-actions button:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

cancel-hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.submit-hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.add-model-actions button[type="button"] {
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid rgb(21 67 176);
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
}

label.label-text {
  position: absolute;
  left: 30px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-cce {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-eleid {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-design {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.addmap-section {
  transform: translate(-1px, 20px);
  display: inline-block;
  margin: 0px 0 0 25px;
  width: 421px;
  height: 196px;
}

#Addinstaller-PersonPhoto {
  border: none;
  color: #000;
  font-family: Poppins;
  font-size: var(--font-size-12);
  width: auto;
}

/* Add Model Ends */

/* Delete Model Starts */

.delete-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: 500px;
  height: 140px;
}

/* Delete Model Ends */

/*add view page responsive css*/

@media only screen and (max-width: 360px) and (max-width: 420px) {
  .add-email {
    margin-left: 0;
    padding: 0 12px 0;
    background: rgba(224, 237, 255, 1);
    border-radius: 5px;
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-name {
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-phone {
    margin-left: 0;
    width: -webkit-fill-available;
    height: 52px;
  }

  #Addinstaller-PersonPhoto {
    margin-left: 0;
    width: -webkit-fill-available;
  }

  input.add-email {
    margin-top: 20px;
  }

  input.add-phone {
    margin-top: 20px;
  }

  input#Addinstaller-PersonPhoto {
    margin-top: 20px;
  }

  label.label-text {
    position: absolute;
    left: 14px;
    top: 23px;
  }

  .photo-main {
    position: absolute;
    right: 12px;
    top: 32px;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
  }

  .unit-main-boxes {
    margin-top: 20px;
    width: -webkit-fill-available;
    height: 52px;
    margin-left: 0;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
    margin-top: 20px;
  }

  .street-type {
    width: 300px;
  }

  .state-main-boxes {
    width: 100%;
    height: 52px;
  }

  .state-code {
    width: 280px;
  }

  .popup-checkbox {
    margin: 0 8px 0 8px;
  }

  .add-cce {
    width: 100%;
    height: 52px;
  }

  .adddetail-input {
    width: 100%;
    height: 52px;
  }

  input.add-ccephoto {
    width: auto;
  }

  .addphoto-main {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  .what-servesection {
    width: auto;
    height: 221px;
    padding: 15px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .add-email {
    margin-left: 0;
    padding: 0 12px 0;
    background: rgba(224, 237, 255, 1);
    border-radius: 5px;
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-name {
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-phone {
    margin-left: 0;
    width: -webkit-fill-available;
    height: 52px;
  }

  #Addinstaller-PersonPhoto {
    margin-left: 0;
    width: -webkit-fill-available;
  }

  input.add-email {
    margin-top: 20px;
  }

  input.add-phone {
    margin-top: 20px;
  }

  input#Addinstaller-PersonPhoto {
    margin-top: 20px;
  }

  label.label-text {
    position: absolute;
    left: 14px;
    top: 23px;
  }

  .photo-main {
    position: absolute;
    right: 12px;
    top: 32px;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
  }

  .unit-main-boxes {
    margin-top: 20px;
    width: -webkit-fill-available;
    height: 52px;
    margin-left: 0;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
    margin-top: 20px;
  }

  .street-type {
    width: 300px;
  }

  .state-main-boxes {
    width: 100%;
    height: 52px;
  }

  .state-code {
    width: 280px;
  }

  .popup-checkbox {
    margin: 0 8px 0 8px;
  }

  .add-cce {
    width: 100%;
    height: 52px;
  }

  .adddetail-input {
    width: 100%;
    height: 52px;
  }

  input.add-ccephoto {
    width: auto;
  }

  .addphoto-main {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  .what-servesection {
    width: auto;
    height: 221px;
    padding: 15px 8px;
  }
}

.profileview-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileview-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: 865px;
  height: 630px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.viewbottom-border {
  border-bottom: 1px solid rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: 865px;
  margin: 15px -20px;
  padding: 0;
}

.profile-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.profile-model-header,
span {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 12px;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
}

.left-profile-photo {
  margin: 20px 0;
}

.right-profile-details {
  margin: 20px 20px;
}

.profile-addresss {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.phone-receiver {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.email-detail {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.addmain-details {
  /* margin: 0 60px 0 0; */
  /* margin: 0 auto; */
}

.view-main-details {
  margin: 10px 0;
  display: flex;
  gap: 4px;
  font-size: 12px;
}

.serve-detail {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licenceImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
}

.licenceImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 360px) {
  .user-form {
    position: relative;
    width: auto;
    height: auto;
  }

  .pas-form {
    width: 86%;
    display: inline-block;
  }

  section.login-box.main-box {
    width: 410px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 320px) {
  .user-form {
    position: relative;
    width: auto;
    height: auto;
  }

  .pas-form {
    width: 86%;
    display: inline-block;
  }

  section.login-box.main-box {
    width: 410px;
    margin: 0 auto;
  }
}

input:hover,
input:focus {
  border: 0;
  outline: 0;
}

input:valid + label,
input:focus + label {
  top: -17px;
  left: 0px;
  outline: 0;
  font-size: 13px;
  font-weight: 400;
}

.main-part-ratailer {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 800px) and (max-width: 1668px) {
  .main-part-ratailer {
    /* margin-left: 17%; */
    width: 83vw;
  }
}

.TeamsTableHeading {
  display: flex;
  padding-left: 1%;
}

.TeamDetailsRowData {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  text-align: center;
  padding-left: 1%;
}

.page-modal-open {
  overflow: hidden;
}

.Logo-image-sideMenu {
  /* width: 70%; */
  padding: 8%;
}

.live-Price-main-section {
  display: flex;
  flex-direction: column;
  width: 85vw;
  /* margin-left: 17%; */
}

.MainOuterContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.sideBarMenu {
  height: 82vh;
}

.Label-text-Login {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
}

.invite-model-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.addphoto-main-Cancel {
  position: absolute;
  right: 40px;
  top: 10px;
}

.TemsTableHeadingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

.CERPage-main-bg {
  background: rgba(224, 237, 255, 1);
  width: 99%;
  height: 55px;
}

/*reset password page design css*/
.ForgetPassword-systemDetails-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-image: url("../public/Assets/Login/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  border-radius: 0;
}

.resetPasswordPage {
  display: flex;
  /* flex-direction: column !important; */
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 400px;
}

.resetpassword-container {
  background: rgba(255, 255, 255, 0.9);
  width: auto !important;
  /* margin: 0 auto; */
  gap: 0;
  display: flex;
  border-bottom: 10px solid rgb(21 67 176);
  justify-content: center;
  border-radius: 0 !important;
  align-items: center;
  /* position: relative; */
  flex-direction: column !important;
  justify-content: center;
  padding: 10px;
  /* max-width: 27% !important; */
}

.reset-text {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
}

.pas-form-reset {
  position: relative;
  width: fit-content;
  height: auto;
  margin-top: 17.47px;
}

input.psd-toggle {
  line-height: 14.36px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: transparent;
}

input.psd-toggle-reset {
  background: rgba(224, 237, 255, 1);
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: 0.5px solid;
  border-radius: 5px;
  border: none;
  display: block;
  padding-left: 50px;
}

.login-form-imag.lock-password {
  background-image: url("../public/Assets/Login/lock-main.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  left: 15px;
  bottom: 5px;
}

.reset-password-text {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 4oo;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

button.next.action-button {
  width: 157px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  line-height: 15.31px;
  margin-top: 10px;
  margin-left: 90px;
}

.ReAssignButtons {
 margin-right: 15px;
width:40%;
background: #1543b0;
color: #fff;
padding: 5px;
border-radius: 10px;
cursor: pointer;
&:hover{
  background:#fff;
  color:#000;
  border: 1px solid #000;
}
}
.input-search {
  width: 95%;
}
.input-search[type="text"] {
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  height: 52px;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  border: 0;
}
@media (max-width:1701) {
  .search-container {
    width: 90% !important;
    min-width: 100%;
    min-height: 52px;
    margin: 15px 0px 15px 20px;
  }
}
.search-container {
  width: 75% !important;
  min-width: 78%;
  min-height: 52px;
  margin: 15px 0px 15px 20px;
}
.search-filter-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status-dropdown{
  border: none;
  background: transparent;
  width:50%;
  height: 10%;
  font-weight: 700;
 color: rgba(44, 58, 100, 1);
 font-size: 0.9rem;
 text-align: center;
}